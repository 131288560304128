import React from "react"
import { graphql } from "gatsby"
import {RiSendPlane2Line} from "react-icons/ri";

import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query NewsletterQuery($id: String!){
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Newsletter = ({data}) => {
  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return  (
    <Layout className="contact-page">
      <SEO
        title={frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title}
      />
      <div className="wrapper">
        <h1>{frontmatter.title}</h1>
        <div className="description" dangerouslySetInnerHTML={{ __html: html }} />
        <form className="contact-form" action="https://tinyletter.com/PWS-Foundation-Theme-Demo" name="newsletter" method="POST" target="_blank" rel="noopener noreferrer" >

          <p>
            <label>Email<input type="email" name="email" required /></label>
          </p>

          <input type="hidden" value="1" name="embed"/>

          <span style={{fontSize: 'xx-small'}}>Form will be submitted in new tab</span>

          <p className="text-align-right">
            <button className="button" type="submit">Submit <span className="icon -right"><RiSendPlane2Line/></span></button>
          </p>
        </form>
      </div>
    </Layout>
  )
}

export default Newsletter
